<template>
  <app-table
    v-if="!loader"
    :keys="keys"
    :array="getData"
    class="payment__table"
  >
    <template #createdAt="{item: payment}">
      <span class="text-gray">
        <span class="payment__table__date__time">
          {{ payment.createdAt }}
        </span>
      </span>
    </template>

    <template #type="{item: payment}">
      <div class="mb-1 text-bold">
        <span v-if="payment.type === 'amount_expense_operation'">
          {{ getExpenseTitle(payment) }}
        </span>
        <span v-else-if="payment.type === 'offer_expense'">
          {{ 'Оффер' }}
        </span>
        <span v-else>
          {{ $t(`info.payments.types.${payment.type}`) }}
        </span>
      </div>
      <div
        v-if="['offer_transfer'].includes(payment.type)"
        class="payment__table__vacancy__description"
      >
        <span
          class="text-bold"
        > {{ `- ${payment.amount}` }}</span>
      </div>
      <div
        v-else-if="['offer_transfer_received'].includes(payment.type)"
        class="payment__table__vacancy__description"
      >
        <span
          class="text-bold text-green"
        > {{ `+ ${payment.amount}` }}</span>
      </div>
      <div
        v-else-if="payment.type === 'office_offer_purchase' && payment.additional"
        class="payment__table__vacancy__description"
      >
        <span
          class="text-bold text-green"
        > {{ `+ ${getOfferNumber(payment)}` }}</span>
      </div>
      <div
        v-else-if="payment.offer && payment.offer.vacancy || payment.type === 'offer_expense'"
        class="payment__table__vacancy__description"
      >
        <!--        <IconEmployee class="mr-1" />-->
        <span
          class="text-bold text-green"
        >-1 </span>&nbsp;
        <span class="text-gray">{{ payment.offer.vacancy.title }}</span>
      </div>
      <div
        v-else-if="payment.type === 'office_amount_refill_operation'"
        class="payment__table__vacancy__description"
      >
        <span
          class="text-gray"
        >{{ 'Неоплачено' }}</span>
      </div>
      <div
        v-else-if="payment.type === 'amount_refill_operation_receipt'"
        class="payment__table__vacancy__description"
      >
        <span
          class="text-green"
        >{{ 'Оплачено' }}</span>
      </div>
    </template>

    <template #amount="{item: payment}">
      <span
        v-if="['offer_transfer_received', 'offer_transfer', 'offer_expense'].includes(payment.type)"
      >
        &nbsp;
      </span>
      <span
        v-else-if="refillTypeList.includes(payment.type)"
        class="text-green text-bold"
        :class="{
          'text-gray': payment.additional && payment.additional.status && payment.additional.status !== 'done'
        }"
      >
        {{ '+' }}
        <AppTextMoney
          v-if="isMoney(payment.amount)"
          :money="payment.amount"
        />
        <span v-else>
          {{ payment.amount }}
        </span>
      </span>
      <span
        v-else
        class="text-bold"
      >
        -
        <AppTextMoney
          v-if="isMoney(payment.amount)"
          :money="payment.amount"
        />
        <span v-else>
          {{ payment.amount }}
        </span>
      </span>
    </template>
    <template #office.title="{item: payment}">
      <span class="text-gray"> {{ payment.office ? payment.office.title : '' }} </span>
    </template>

    <template #responsible.fullName="{item: payment}">
      <span class="text-gray"> {{ payment.responsible.fullName }} </span>
    </template>

    <template #file="{item: payment}">
      <span class="payment__table__email">
        <IconFileDoc
          v-if="payment.file && payment.file.id"
          :width="14"
          :height="20"
          style="vertical-align: middle;text-align: center; margin-bottom: 8px"
          @click.native="downloadFile(payment)"
        /><br>
        <IconFileDoc
          v-if="getBill(payment)"
          :width="14"
          :height="20"
          style="vertical-align: middle;text-align: center; margin-bottom: 8px"
          @click.native="downloadFile({file: getBill(payment)})"
        /><br>
        <IconFileDoc
          v-if="getCompensation(payment)"
          :width="14"
          :height="20"
          style="vertical-align: middle;text-align: center; margin-bottom: 8px"
          @click.native="downloadFile({file: getCompensation(payment)})"
        /><br>
        <IconFileDoc
          v-if="getOfferExpense(payment)"
          :width="14"
          :height="20"
          style="vertical-align: middle;text-align: center; margin-bottom: 8px"
          @click.native="downloadFile({file: getOfferExpense(payment)})"
        />
      </span>
    </template>
  </app-table>
  <AppLoader
    v-else
    class="full-height"
  />
</template>

<script>
import AppTable from '@/components/common/AppTable'
import AppTextMoney from '@/components/common/AppTextMoney'
import AppLoader from '@/components/AppLoader'
import IconFileDoc from '@/components/common/icons/IconFileDoc'
import { mapState } from 'vuex'

export default {
  name: 'PaymentTable',
  components: { IconFileDoc, AppTable, AppTextMoney, AppLoader },
  props: {
    paymentList: {
      type: Array,
      required: true
    },
  },
  data: (vm) => ({
    keys: [
      {
        label: vm.$t('info.payments.table.date_time'),
        value: 'createdAt',
        config: {
          canSort: false
        }
      },
      {
        label: vm.$t('info.payments.table.type'),
        value: 'type',
        config: {
          canSort: false
        }
      },
      {
        label: vm.$t('info.payments.table.amount'),
        value: 'amount',
        config: {
          canSort: false
        }
      },
      {
        label: 'Объект',
        value: 'office.title',
        config: {
          canSort: false
        }
      },
      {
        label: vm.$t('info.payments.table.responsible'),
        value: 'responsible.fullName',
        config: {
          canSort: false
        }
      },
      {
        label: '',
        value: 'file',
        config: {
          canSort: false
        }
      },
    ],
    refillTypeList: [
      'office_amount_refill_operation',
      'amount_refill_operation_receipt',
      'money_transfer_received',
      // 'office_offer_purchase',
      'offer_transfer_received'
    ]
  }),
  computed: {
    ...mapState({
      loader: (state) => state.payment.loader
    }),
    currentUser () {
      return this.$store.state.users.info
    },
    officeExists () {
      return this.$store.state.office.userOffice && this.$store.state.office.userOffice.id
    },
    getData () {
      const list = [
        ...this.paymentList
      ]
      if (!this.officeExists) {
        for (const payment of list) {
          if (payment.type === 'money_transfer') {
            const receiver = {
              ...payment,
              office: this.getTransferReceiver(payment),
              type: 'money_transfer_received'
            }
            list.push(receiver)
          } else if (payment.type === 'offer_transfer') {
            const receiver = {
              ...payment,
              office: this.getTransferReceiver(payment),
              type: 'offer_transfer_received'
            }
            list.push(receiver)
          }
        }
      }
      return list.sort((a, b) => {
        if (a.additional.createdAt > b.additional.createdAt) {
          return -1
        }
        if (a.additional.createdAt < b.additional.createdAt) {
          return 1
        }
        return 0
      })
    }
  },
  created () {
  },
  methods: {
    getExpenseTitle (payment) {
      const { pretension, bill } = payment.additional
      if (bill && !!bill.id) {
        if (pretension && !!pretension.id) {
          return 'Оффер, оплата услуг и компенсация'
        }
        return 'Оффер, оплата услуг'
      }

      if (pretension && !!pretension.id) {
        return 'Оффер и претензия'
      }
      return 'Оффер, оплата услуг'
    },
    getCompensation (payment) {
      const { pretension } = payment.additional
      if (pretension && !!pretension.id) {
        return pretension
      }
      return null
    },
    getBill (payment) {
      const { bill } = payment.additional
      if (bill && !!bill.id) {
        return bill
      }
      return null
    },
    getTransferReceiver (payment) {
      const { receiverOffice, receiverOrganization } = payment.additional
      if (receiverOffice && !!receiverOffice.id) {
        return receiverOffice
      }
      return receiverOrganization
    },
    getCompensationAmount (payment) {
      const pretension = this.getCompensation(payment)
      if (!pretension) {
        return 0
      }
      const { amount } = pretension
      return parseFloat(amount) + parseFloat(payment.amount)
    },
    getOfferExpense (payment) {
      const { offerExpense } = payment.additional
      if (offerExpense && !!offerExpense.id) {
        return offerExpense
      }
      return null
    },
    getOfferNumber (payment) {
      const { offerNumber } = payment.additional
      return offerNumber
    },
    downloadFile (item) {
      if (item.file && item.file.path) {
        const win = window.open(item.file.path, '_blank')
        win.focus()
      }
    },
    isMoney (val) {
      return val.toString().includes('.')
    }
  }
}
</script>

<style lang="sass" scoped>
  $countColor: #A7A7A7
  $fontWeightBold: 800

  .payment__table
    font-family: $mainFont
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 15px

    .text-green
      color: #8BD118

    .text-gray
      color: rgba(0, 0, 0, 0.6)

    .text-bold
      font-weight: 800

    .mb-1
      margin-bottom: 0.7rem

    .mr-1
      margin-right: 0.7rem

    &__vacancy__description
        display: flex
        align-items: center

    &__date
      color: rgba(0, 0, 0, 0.60)
      &__time
        font-weight: $fontWeightBold

</style>

<style lang="sass">
  .payment__table .table__item
    vertical-align: initial
</style>
