<template>
  <div class="filters">
    <div class="tabs">
      <div
        v-for="(it, key) in filters"
        :key="key"
        class="tabs__item"
        :class="{'tabs__item--active': key === typeSelected}"
        @click="changeTypeFilter(key)"
      >
        {{ $t(`info.payments.types.${key}`) }}
      </div>
      <button
        class="btn-outline"
        :disabled="isLoading"
        @click="$emit('changeFilters', params)"
      >
        Искать
      </button>
    </div>
    <AppDateFilter @changeFilters="updateDateFilters" />
  </div>
</template>
<script>
import AppDateFilter from '@/components/common/AppDateFilter'
import { mapState } from 'vuex'
import userUtil from '@/utils/user-util'

export default {
  name: 'PaymentsFilterBarCenter',
  components: {
    AppDateFilter
  },
  data: () => ({
    typeSelected: 'all',
    dateFilters: {},
  }),
  computed: {
    ...mapState({
      isLoading: (state) => state.payment.loader
    }),
    officeExists () {
      return this.$store.state.office.userOffice && this.$store.state.office.userOffice.id
    },
    currentUser () {
      return this.$store.state.users.info
    },
    filters () {
      const filters = {
        all: {
          params: [],
        },
        amount_refill_operation_receipt: {
          params: [
            'office-amount-refill-operation',
            'amount-refill-operation-receipt',
            // 'money-transfer-received'
          ],
        },
        office_offer_purchase: {
          params: [
            'office-offer-purchase',
            'offer-transfer-received'
          ],
        },
        offer_expense: {
          params: [
            'offer-expense',
            // 'offer-transfer',
            // 'amount-expense-operation',
            // 'money-transfer'
          ],
        },
      }

      const userFinanceFilters = userUtil.isRoot(this.currentUser) ? {
        ...filters,
        transfer: {
          params: [
            'offer-transfer',
            'money-transfer',
            'money-transfer-received',
            'offer-transfer-received'
          ],
        },
      }
        : filters

      if (this.myOrganizationIsOfferLess) {
        delete userFinanceFilters.office_offer_purchase
        delete userFinanceFilters.offer_expense
        userFinanceFilters.transfer.params = [
          'money-transfer',
          'money-transfer-received',
        ]
      }
      return userFinanceFilters
    },
    params () {
      const params = this.filters[this.typeSelected].params.reduce((acc, it, i) => {
        acc[`typeList[${i}]`] = it
        return acc
      }, { ...this.dateFilters })

      return params
    }
  },
  methods: {
    changeTypeFilter (type) {
      this.typeSelected = type
    },
    updateDateFilters (params) {
      this.dateFilters = { ...params }
    }
  }
}
</script>

<style lang="sass" scoped>
.tabs
  display: flex
  padding-bottom: 15px
  margin-bottom: 15px
  font-weight: 600
  font-size: 16px
  line-height: 20px
  color: black
  border-bottom: 1px solid rgba(0, 0, 0, 0.15)

  &__item
    padding: 11px 25px
    opacity: 0.4
    cursor: pointer

    &--active
      opacity: 1
      background-color: white
      border-radius: 25px
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1)
</style>
