<template>
  <AppMainContainer center-class="offer">
    <template #subheader>
      <PageHeader :isAdmin="isOrganizationRoot && !officeExists" />
      <OfficeSelect />
    </template>
    <template #center>
      <PaymentsFilterBarCenter
        @changeFilters="changeFilters"
      />
      <PaymentsTable :payment-list="paymentList" />
    </template>
  </AppMainContainer>
</template>
<script>
import { mapActions } from 'vuex'
import userUtil from '@/utils/user-util'
import OfficeSelect from '@/components/common/OfficeSelect'
import AppMainContainer from '@/components/common/layout/AppMainContainer'
import PaymentsFilterBarCenter from '@/components/payments/PaymentsFilterBarCenter'
import PaymentsTable from '@/components/payments/PaymentsTable'
import PageHeader from '@/components/subheaders/PageHeader.vue'

export default {
  components: {
    PageHeader,
    AppMainContainer,
    PaymentsFilterBarCenter,
    PaymentsTable,
    OfficeSelect,
  },
  data () {
    return {
      filters: {},
    }
  },
  computed: {
    officeExists () {
      return this.$store.state.office.userOffice && this.$store.state.office.userOffice.id
    },
    paymentList () {
      // const list = this.$store.state.payment.items
      return this.$store.state.payment.items
    },
    user () {
      return this.$store.state.users.info
    },
    isOrganizationRoot () {
      return userUtil.isRoot(this.user) || userUtil.isAdmin(this.user)
    },
  },
  watch: {
    officeExists (val, prev) {
      if (!val && !userUtil.isRoot(this.user)) {
        return null
      }
      this.fetchPaymentList(this.filters)
    },
    '$route.params.id': function (officeId) {
      if (!officeId && !userUtil.isRoot(this.user)) {
        return
      }
      this.fetchPaymentList(this.filters)
    }
  },
  async created () {
    if (!this.$route.params.id && userUtil.isRoot(this.user)) {
      this.fetchPaymentList(this.filters)
    } else if (this.officeExists || !userUtil.isRoot(this.user)) {
      this.fetchPaymentList(this.filters)
    }
  },
  methods: {
    ...mapActions({
      fetchPaymentList: 'payment/fetchPaymentList',
    }),
    changeFilters (params) {
      this.filters = { ...params }
      this.fetchPaymentList(this.filters)
    },
  },
}
</script>
<style>
</style>
